
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"
import { CAMERA_MARKER_TYPES } from "@evercam/3d/components/constants"
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"
import CameraMapInfoWindow from "@/components/map/CameraMapInfoWindow"

export default {
  components: {
    CopyToClipboardBtn,
    CameraMapInfoWindow,
  },
  props: {
    context: {
      type: Object,
      default: undefined,
    },
  },
  data: () => ({
    menuIsOpen: false,
    cameraMarkerTypes: CAMERA_MARKER_TYPES,
  }),
  computed: {
    ...mapStores(useCameraStore),
    cameraPosition() {
      const cartographic = this.context.data.cartographic

      return `
        ${parseFloat(cartographic.latitude).toFixed(6)},
        ${parseFloat(cartographic.longitude).toFixed(6)}
      `
    },
    positionDMS() {
      const lat = this.context.data.cartographic.latitude
      const latitude = this.toDegreesMinutesAndSeconds(lat)
      const latitudeCardinal = lat >= 0 ? "N" : "S"

      const lng = this.context.data.cartographic.longitude
      const longitude = this.toDegreesMinutesAndSeconds(lng)
      const longitudeCardinal = lng >= 0 ? "E" : "W"

      return latitude + latitudeCardinal + "+" + longitude + longitudeCardinal
    },
  },
  watch: {
    context(value) {
      this.menuIsOpen = !!value?.camera || !!value?.data.pano
      if (this.menuIsOpen && !!value?.camera) {
        this.cameraStore.selectCamera(value.camera)
      }
    },
  },
  methods: {
    toDegreesMinutesAndSeconds(coordinate) {
      const absolute = Math.abs(coordinate)
      const degrees = Math.floor(absolute)
      const minutesNotTruncated = (absolute - degrees) * 60
      const minutes = Math.floor(minutesNotTruncated)
      const seconds = Math.floor((minutesNotTruncated - minutes) * 60)

      return degrees + "°" + minutes + "'" + seconds + '"'
    },
  },
}
